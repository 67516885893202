import React from "react"
import { graphql, withPrefix } from "gatsby"
import Layout from "../components/layout"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import components from '../components/MDXComponents'
import TocBase from "../components/toc"
import ChartWithCategory from "../components/chart-with-category"
import { JobBlock } from "../components/category-block"

export default function RemarkTop({ data, pageContext }) {
  const post = data.mdx
  const category = post.fields.category
  const { isCharm, charmIcon } = pageContext

  const Toc = ({color}) => <TocBase items={post.tableOfContents.items} color={color} />
  const shortcodes = { Toc, ...ChartWithCategory({category}), ...components }

  return (
    <Layout slug={post.fields.slug} category={category} showJob={isCharm} showCharm={true} {...data.meta.nodes[0]}>
      {isCharm ? (
        <div class="c-page-title c-page-title-charm">
          <span>歯科衛生士の魅力</span>
          <div class="c-page-title-charm-inner">
            {post.frontmatter.heading ? 
            <h1 dangerouslySetInnerHTML={{__html : post.frontmatter.heading}} /> : 
            <h1>{post.frontmatter.title}</h1>
            }
            <picture>
              <source media="(min-width: 768px)" srcset={withPrefix(`/img/common/${charmIcon}_pc@2x.png`)} sizes="100vw" />
              <source media="(max-width: 767px)" srcset={withPrefix(`/img/common/${charmIcon}_sp@2x.png`)} sizes="100vw" />
              <img src={withPrefix(`/img/common/${charmIcon}_pc@2x.png`)} alt={post.frontmatter.title} />
            </picture>
          </div>
        </div>
      ): (
      <div className="c-page-title">
        <h1>{post.frontmatter.title}</h1>
      </div>
      )}

      <MDXProvider components={shortcodes}>
        <MDXRenderer>{post.body}</MDXRenderer>
      </MDXProvider>

      {isCharm ? false : <JobBlock naruniha={post.fields.slug === "/shigoto/" ? true : false} shigoto={post.fields.slug === "/naruniha/" ? true : false} />}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    meta: allMeta(filter: {slug: {eq: $slug}}) {
      nodes {
        title
        description
        keywords
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date
        title
        heading
        description
      }
      fields {
        slug
        category
      }
      tableOfContents(maxDepth: 3)
    }
  }
`